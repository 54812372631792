body {
  margin: 0;
  font-family: "TangakRegular";
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "TangakRegular";
  src: local("TangakRegular"),
  url("./accets/font/Tangak.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Madebarista";
  src: local("Madebarista"),
  url("./accets/font/Madebarista.ttf") format("truetype");
  font-weight: normal;
}