* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: #205656;
}

.App {
    width: 1200px;
    margin: auto;
}


@media (max-width: 1200px) {
    .App {
        width: 100%;
    }
}

@media (max-width: 992px) {

}

@media (max-width: 768px) {

}

@media (max-width: 480px) {

}

@media (max-width: 320px) {

}