.prod__all_block {
    display: grid;
    width: 960px;
    max-height: 77vh;
    grid-template-columns: repeat(4, 195px);
    gap: 5px 35px;
    margin-left: 48px;
    overflow: auto;
}

.prod__block {
    height: 331px;
    position: relative;
    background: #66A7AB;
    border-radius: 5px;
    transition: opacity 1s;
}

.prod__img {
    display: flex;
    justify-content: center;
}


.prod__img img {
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.prod__name {
    margin: 0 5px;
    text-align: center;
    position: absolute;
    top: 5px;
    border-radius: 5px;
    border: 1px solid #66A7AB;
    background: rgb(217, 217, 217, 0.70);
    color: #205656;
    line-height: 1.1;
    padding: 1px 2px 0 2px;
}

.prod__description {
    font-size: 12px;
    background: rgb(217, 217, 217);
    border: 1px solid #66A7AB;
    border-radius: 5px;
    padding: 3px;
    line-height: 1.1;
}

.prod__info__block {
    position: absolute;
    opacity: 0;
    top: 45px;
    border: 1px solid #66A7AB;
    border-radius: 5px;
}

.prod__price__basket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.basket__btn__prod {

    color: #fff;
    cursor: pointer;
}

.prod__img:hover .prod__info__block {
    opacity: 1;

}

.prod__price {
    background: rgb(217, 217, 217, 0.50);
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 20px;
    color: #205656;
}


#style-1::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgb(153, 154, 158);
    border-radius: 10px;
    background-color: #999a9e;
}

#style-1::-webkit-scrollbar
{
    width: 5px;
    background-color: #999a9e;
}

#style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(32, 86, 86);
    background-color: #205656;
}

@media (max-width: 1200px) {
    .prod__all_block {
        grid-template-columns: repeat(3, 21vw);
        grid-template-rows: max-content;
        max-height: 82vh;
        margin-left: 40px;
        gap: 10px 20px;
    }
    .prod__block {
        height: 361px;
    }
}

@media (max-width: 992px) {
    .prod__all_block {
        grid-template-columns: repeat(2, 30vw);
        max-height: 82vh;
        margin-left: 40px;
        gap: 10px 20px;
    }
    .prod__block {
        height: 408px;
    }
}
@media (max-width: 912px) {

    .prod__block {
        height: 450px;
    }
}
@media (max-width: 820px) {

    .prod__block {
        height: 408px;
    }
}

@media (max-width: 768px) {
    .prod__all_block {
        grid-template-columns: repeat(3, 28vw);
        margin-left: 5px;
        max-height: 73vh;
    }
    .prod__block {
        height: 370px;
    }
    .prod__name {
        font-size: 20px;
    }
    .prod__price {
        font-size: 22px;
    }
    .basket__btn {
        font-size: 22px;
    }
}

@media (max-width: 540px) {
    .prod__name {
        font-size: 14px;
    }
    .basket__btn {
        font-size: 15px;
    }
    .prod__block {
        height: 268px;
    }
    .prod__description {
        font-size: 9px;
    }
}

@media (max-width: 480px) {
    .prod__all_block {
        display: block;
        width: 315px;
    }
    .prod__block {
        margin-bottom: 20px;
        height: 529px;
    }
    .prod__name {
        font-size: 19px;
    }
    .prod__description {
        font-size: 22px;
    }
    .prod__info__block {
        top: 80px;
    }
    .prod__price {
        font-size: 38px;
    }
    .basket__btn {
        font-size: 35px;
    }
    .prod__info__block {
        z-index: 0;
    }
}

@media (max-width: 320px) {

}