.ctg__block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.ctg__link {
    height: 240px;
    position: relative;
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
}

.ctg__link:nth-child(3),
.ctg__link:nth-child(4) {
    font-size: 13px;
}

.ctg__link img {
    width: 100%;
    border-radius: 5px;
}

.ctg__name__block {
    width: 200px;
    position: absolute;
    top: 155px;
    right: 0;
    display: flex;
    background: rgb(102, 167, 171, 0.8);
    border-radius: 5px 0 0 5px;
    align-items: center;
    padding: 10px 0 10px 10px;
}

.ctg__arrow {
    width: 30px;
    height: 30px;
    background: #D8B164;
    border-radius: 50%;
    margin-right: 10px;
}

.ctg__hover {
    display: none;
    position: absolute;
    width: 100%;
    height: 228px;
    border-radius: 5px;
    top: 0;
    background: rgb(102, 167, 171, 0.8);
}

.ctg__link:hover .ctg__hover {
    display: block;
}

@media (max-width: 1200px) {
    .nav__and__product {
        height: 84vh;
    }

    .ctg__block {
        grid-template-columns: 1fr 1fr 1fr;
        height: 670px;
    }

    .ctg__name__block {
        width: 200px;
        top: 230px;
    }
    .ctg__arrow {
        width: 30px;
        height: 30px;
    }
    .ctg__link {
        font-size: 20px;
        width: 32vw;
    }

    .ctg__link:nth-child(3),
    .ctg__link:nth-child(4) {
        font-size: 16px;
    }

}

@media (max-width: 992px) {
    .nav__and__product {
        height: 84vh;
    }

    .ctg__block {
        grid-template-columns: 1fr 1fr 1fr;
        height: 610px;
    }

    .ctg__name__block {
        width: 200px;
        top: 200px;
    }
    .ctg__arrow {
        width: 30px;
        height: 30px;
    }
    .ctg__link {
        font-size: 20px;
    }

    .ctg__link:nth-child(3),
    .ctg__link:nth-child(4) {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .ctg__block {
        height: 500px;
    }

    .ctg__name__block {
        width: 200px;
        top: 160px;
    }
    .ctg__arrow {
        width: 30px;
        height: 30px;
    }
    .ctg__link {
        font-size: 20px;
    }

    .ctg__link:nth-child(3),
    .ctg__link:nth-child(4) {
        font-size: 16px;
    }

}

@media (max-width: 540px) {
    .ctg__block {
        height: 300px;
    }

    .ctg__name__block {
        width: 150px;
        top: 100px;
    }
    .ctg__arrow {
        width: 22px;
        height: 22px;
    }
    .ctg__link {
        width: 31vw;
        height: 170px;
        font-size: 15px;
    }

    .ctg__link:nth-child(3),
    .ctg__link:nth-child(4) {
        font-size: 11px;
    }

}

@media (max-width: 480px) {
    .nav__and__product {
        height: 220vh;
        justify-content: center;
        overflow: auto;
    }
    .ctg__block {
        grid-template-columns: 1fr;
    }
    .ctg__link {
        height: 308px;
        width: 308px;
        font-size: 24px;
    }
    .ctg__name__block {
        width: 280px;
        top: 210px;
    }
    .ctg__arrow {
        width: 40px;
        height: 40px;
    }
    .ctg__link:nth-child(3),
    .ctg__link:nth-child(4) {
        font-size: 20px;
    }
}

@media (max-width: 320px) {

}