.admin_panel_reklama_block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.photo_reklama_block {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
}

.photo_reklama_btn_block {
    width: 105px;
    display: flex;
    background: #205656;
    border-radius: 5px;
    padding: 3px 5px;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
    transition: 0.5s;
    cursor: pointer;
    margin-bottom: 10px;
}

.photo_reklama_btn_block:hover {
    background: #184141;
}

.photo_reklama_btn_block img {
    width: 24px;
    margin-right: 5px;
}

.photo_reklama_btn {
    color: #D8B164;
}

.photo_reklama_btn_block_red {
    background: #a40a0a;
}

.photo_reklama_btn_block_red:hover {
    background: #930505;
}

.file-upload_rek {
    height: 30px;
    width: 105px;
    margin-left: 0;
    margin-bottom: 10px;
}

.photo_reklama_img {
    margin-left: 20px;
    text-align: center;
    width: 180px;
}

.photo_reklama_img img {
    width: 100%;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {

}

@media (max-width: 480px) {

}

@media (max-width: 320px) {

}