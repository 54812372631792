.orders__block {
    padding: 0 0 10px 10px;
}

.order_new_and_g_ready {
    display: flex;
}

.new__order {
    width: 500px;
}

.order_txt {
    font-size: 20px;
    color: #205656;
}

.order_txt_g_r {
    color: #fff;
}

.new_order_block_height {
    height: 45vh;
    border-radius: 10px;
    background: #D9D9D9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
    overflow: auto;
}

.new_order_block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 5px;

}

.new_order_block_height_g_r,
.order_txt_g_r {
    margin-left: 10px;
}

.new_order_block_height_g_r {
    background: #66A7AB;
}

.order_small {
    text-decoration: none;
    position: relative;
    height: 50px;
    border-radius: 10px;
    padding: 5px;
    background: #66A7AB;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
}

.order_small_g_r {
    background: #D9D9D9;
}

.num_date {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.num_block {
    display: flex;
}

.num_date div {
    color: #fff;
}
.num_date_g_r div {
    color: #205656;
}

.num_order {
    margin-left: 3px;
}

.date_order {
    font-size: 12px;
}

.order_address {
    display: flex;
}

.order_address div {
    margin-right: 5px;
    color: #000;
}



.prod_small_block_hover {
    display: none;
    position: absolute;
    top: 60px;
    border-radius: 10px;
    border: 1px solid #205656;
    padding: 4px;
    background: #fff;
    z-index: 1000;
}

.prod_small_block_hover div {
    color: #000;
}

.prod_small_block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.prod_small_block:last-child {
    margin-bottom: 0;
}

.prod_small {
    font-size: 12px;
}

.kol_small_block {
    display: flex;
}

.kol_small_block div {
    font-size: 12px;
}

.order_small:hover .prod_small_block_hover {
    display: block;
}

.order_small:hover:before {
    display: block;
}




.new_order_block_height_good {
    height: 23vh;
    background: #205656;
    overflow: auto;
}

.new_order_block_good {
    grid-template-columns: repeat(4, 1fr);
}

.order_small_good {
    background: #D8B164;
}

#style-4::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgb(32, 86, 86);
    border-radius: 10px;
    background-color: #205656FF;
}

#style-4::-webkit-scrollbar
{
    width: 5px;
    background-color: #205656FF;
}

#style-4::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(215, 176, 109);
    background-color: #D7B06DFF;
}

@media (max-height: 600px) {
    .new_order_block_height {
        height: 41vh;
    }
    .new_order_block_height_good {
        height: 23vh;
    }
}

@media (max-width: 768px) {
    .order_new_and_g_ready {
        display: block;
    }
    .new__order {
        width: 95vw;
    }
    .new_order_block_height_g_r, .order_txt_g_r {
        margin-left: 0;
    }
    .order_txt {
        font-size: 24px;
    }
    .new_order_block {
        grid-template-columns: repeat(2, 1fr);
    }
    .order_small {
        height: 67px;
    }
    .new_order_block_height {
        height: 23vh;
    }
}

@media (max-width: 480px) {
    .orders__block {
        width: 98vw;
    }
    .new__order {
        width: 94vw;
    }
    .new_order_block {
        grid-template-columns: repeat(1, 1fr);
    }
    .new_order_block_height {
        height: 30vh;
    }
}

@media (max-width: 320px) {

}