.nav_btn_visible_block {
    display: none;
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: #d7a33b 2px solid;
    left: 10px;
    top: 165px;
    background: #fff;
    z-index: 100;
}

.nav_btn_visible_line {
    position: relative;
    height: 2px;
    width: 25px;
    background: #184141;
    margin: 0 auto;
}

.nav_btn_visible_line:after {
    content: "";
    position: absolute;
    top: 7px;
    height: 2px;
    width: 25px;
    background: #184141;
    margin: 0 auto;
}

.nav_btn_visible_line:before {
    content: "";
    position: absolute;
    top: -7px;
    height: 2px;
    width: 25px;
    background: #184141;
    margin: 0 auto;
}


@media (max-width: 768px) {
    .nav_btn_visible_block {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav_btn_visible_block {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav_btn_visible_block {
        width: 50px;
        height: 50px;
        top: 110px;
    }

    .nav_btn_visible_line, .nav_btn_visible_line:before, .nav_btn_visible_line:after {
        width: 35px;
        height: 3px;
    }
}

@media (max-width: 480px) {
    .nav_btn_visible_block {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav_btn_visible_block {
        width: 50px;
        height: 50px;
        top: 100px;
    }

    .nav_btn_visible_line, .nav_btn_visible_line:before, .nav_btn_visible_line:after {
        width: 35px;
    }
}