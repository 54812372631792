.nav__and__product {
    display: flex;
    background: #9A9B9F;
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 76vh;
    padding: 10px;
}

@media (max-width: 1200px) {
    .nav__and__product {
        height: 84vh;
    }
}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .nav__and__product {
        height: 82vh;
    }
}

@media (max-width: 540px) {
    .nav__and__product {
        height: 70vh;
    }
}

@media (max-width: 480px) {
    .nav__and__product {
        height: 79vh;
    }
}

@media (max-width: 320px) {

}