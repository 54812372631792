.admin_panel_block {
    width: 85%;
    padding: 0 10px 10px 10px;
}

.admin_panel_txt {
    width: 40%;
    border-bottom: #205656 1px solid;
    font-size: 30px;
    color: #205656;
    margin-bottom: 10px;
}

.admin_panel {
    background: #D9D9D9;
    border-radius: 5px;
    padding: 10px;
    align-items: center;
}

.admin_panel_line,
.admin_panel_btn_block,
.admin_panel_btn_add,
.file-upload,
.admin_panel_input_block,
.admin_panel_btn_sps,
.admin_panel_line_block {
    display: flex;
    justify-content: space-between;
}

.admin_panel_line {
    margin-bottom: 10px;
}

.admin_panel_name {
    color: #205656;
    margin-right: 10px;
    width: 180px;
}

.admin_panel_input_block input[type="text"] {
    border-radius: 5px;
    border: none;
    padding: 5px;
    margin-right: 5px;
    color: #205656;
    font-family: "TangakRegular";
}

.admin_panel_input_block_price input {
    width: 80px;
    text-align: right;
}

.admin_panel_input_block_redact input {
    width: 465px;
}

.file-upload {
    position: relative;
    cursor: pointer;
    background: #D8B164;
    color: #205656;
    align-items: center;
    padding: 0 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
    margin-left: 5px;
}

.file-upload p {
    font-size: 14px;
}

.file-upload input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;

}

.admin_panel_btn_add {
    align-items: center;
    background: #205656;
    border-radius: 5px;
    padding: 0 5px;
    color: #D8B164;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
}

.admin_panel_btn_add img {
    width: 24px;
    margin-right: 5px;
}

.admin_panel_btn_sps {
    align-items: center;
    background: #66A7AB;
    border-radius: 5px;
    padding: 0 5px;
    color: #205656;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
}

.admin_panel_btn_sps img {
    width: 24px;
    margin-right: 5px;
}

.admin_panel_input_block textarea {
    border: none;
    border-radius: 5px;
    padding: 5px;
    width: 465px;
    height: 120px;
    resize: none;
    color: #205656;
    font-family: "TangakRegular";
}

select {
    appearance: none;
    outline: none;
    box-shadow: none;
    border: none;
    background-image: none;
}

select::-ms-expand {
    display: none;
}

select {
    position: relative;
    display: flex;
    width: 135px;
    height: 26px;
    border-radius: 5px;
    padding-left: 5px;
    cursor: pointer;
    font-family: "TangakRegular";
    margin-left: 5px;
}

select:after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 10px;
    background: #D8B164;
    cursor: pointer;
    color: #000;
}

.admin_panel_users {
    height: 260px;
    overflow: auto;
}

.admin_panel_user_result_block {
    display: flex;
}

.admin_panel_user_result_text {
    font-size: 24px;
    margin-right: 10px;
    margin-top: 10px;
    color: #205656;
}

.admin_panel_users_list_block {
    display: flex;
}

.admin_panel_users_list {
    width: 160px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
    color: #205656;
}

@media (max-width: 1200px) {
    .admin_panel_block {
        width: 72%;
    }

    .admin_panel_txt {
        width: 45%;
        font-size: 18px;
    }
    .admin_panel_name {
        width: 110px;
        font-size: 14px;
    }
}

@media (max-width: 992px) {
    .admin_panel_block {
        overflow: auto;
    }
    .admin_panel_txt {
        width: 45%;
        font-size: 18px;
    }
    .admin_panel_reklama_block {
        display: block;
    }
    .admin_panel_name {
        width: 110px;
    }
}

@media (max-height: 600px) {
    .admin_panel_txt {
        font-size: 22px;
    }

}

@media (max-width: 768px) {
    .admin_panel_block {
        width: 100%;
    }
}

@media (max-width: 480px) {

}

@media (max-width: 320px) {

}
