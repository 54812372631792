.update_product_block {
    margin-left: 10px;
}

.update_product {
    position: relative;
    background: #D9D9D9;
    border-radius: 5px;
    padding: 10px;
}

.update_product_title {
    font-size: 24px;
    color: #184141;
}

.update_product_spisok_block {
    width: 980px;
    height: 660px;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    overflow: auto;
}

.update_product_item {
    display: flex;
    padding: 3px 0;
    border-bottom: #D9D9D9 1px solid;

}

.update_product_name {
    width: 700px;
}

.update_product_price {
    width: 45px;
    margin-right: 50px;
}


.update_product_close {
    width: 15px;
    height: 15px;
    position: absolute;
    padding: 2px;
    right: -20px;
    top: -20px;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
}

.update_product_close:after {
    content: "";
    display: block;
    background-color: #d70000;
    position: absolute;
    top: 48%;
    width: 9px;
    height: 1px;
    transform: rotate(45deg);
}

.update_product_close:before {
    content: "";
    display: block;
    position: absolute;
    top: 48%;
    background-color: #d70000;
    width: 9px;
    height: 1px;
    transform: rotate(315deg);
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-height: 600px) {
    .update_product_spisok_block {
        height: 385px;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 480px) {

}

@media (max-width: 320px) {

}