.basket_adr_block {
    display: flex;
    padding: 0 10px;
}

.basket_block {
    width: 580px;
}

.adr_block {
    width: 410px;
}

.text_img_block {
    display: flex;
    align-items: center;
}

.text_img_block img {
    width: 24px;
}

.text_img_block h3 {
    margin-left: 10px;
}

.order__block {
    height: 70vh;
    background: #D9D9D9;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
    overflow: auto;
}

.order {
    display: flex;
    width: 100%;
    background: #66A7AB;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
    margin-bottom: 5px;
}

.prod_name {
    width: 77%;
    padding: 5px;
    margin-right: 5px;
}

.sum__block {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding: 3px;
    border-radius: 10px;
    border: 2px solid #D8B164;
}

.col_block {
    margin-right: 5px;
    align-items: center;
    text-align: center;
}

.col_pm {
    display: flex;
    justify-content: space-around;
    background: #D8B164;
    border-radius: 5px;
    margin-bottom: 2px;
    align-items: center;
}

.col_pm_prod {
    margin-top: 5px;
    background: inherit;
}

.col_btm {
    cursor: pointer;
}


.col_btm_prod {
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 4px;
    background: #D8B164;
    border-radius: 5px;
}

.col_num {
    background: #D9D9D9;
    padding: 0 2px;
    margin: 0 5px;
}

.col_num_prod {
    background: inherit;
}

.price {
    margin-bottom: 2px;
}

.price, .sum_price {
    padding: 0 3px;
    border-radius: 5px;
    background: #D9D9D9;
    text-align: center;
}

.del_prod {
    width: 15px;
    height: 15px;
    position: relative;
    padding: 2px;
    right: 0;
    top: 5px;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
}

.del_prod:after {
    content: "";
    display: block;
    background-color: #d70000;
    position: absolute;
    top: 48%;
    width: 9px;
    height: 1px;
    transform: rotate(45deg);
}

.del_prod:before {
    content: "";
    display: block;
    position: absolute;
    top: 48%;
    background-color: #d70000;
    width: 9px;
    height: 1px;
    transform: rotate(315deg);
}


.str__block {
    background: #66A7AB;
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
}

.str__block div {
    margin-left: 2px;
}

.str__input,
.home__input,
.komm__input,
.pay__req {
    background: #D9D9D9;
    border: none;
    border-radius: 5px;
    padding: 4px;
}

.str__input,
.komm__input {
    width: 100%;
    resize: none;
}


.home__input {
    width: 35px;
    text-align: center;
    margin: 0 5px;
}

.str__home__block {
    display: flex;
    margin-top: 10px;
}

.komm__txt {
    margin-top: 5px;
}

.pay__req__block {
    display: flex;
    margin-top: 5px;
}

.pay__req__txt {
    margin-right: 5px;
}

.pay__req {
    width: 100px;
}

.total__sum__block {
    display: flex;
    justify-content: end;
    margin-top: 10px;
    align-items: center;

}

.total__txt {
    font-size: 24px;
    color: #fff;
    margin-right: 10px;

}

.total {
    background: #D8B164;
    width: 120px;
    font-size: 24px;
    border-radius: 10px;
    text-align: center;
    padding: 5px;
    color: #205656;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
}

.btn__order {
    display: flex;
    align-items: center;
    background: #205656;
    border-radius: 10px;
    padding: 5px 10px;
    transition: 0.5s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
    cursor: pointer;
    text-decoration: none;

}

.btn__order:hover {
    background: #184141;
}

.btn__order__txt {
    color: #D8B164;
    margin-left: 10px;
    font-size: 20px;
}

.btn__order__text {
    margin-right: 10px;
    font-size: 16px;
    color: #184141;
}

.usl_block {
    margin-top: 10px;
    text-align: end;
}

.btn_on_time {
    font-size: 20px;
    text-align: end;
}

.btn_on_time b {
    color: #184141;
}

#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(215, 215, 215);
    border-radius: 10px;
    background-color: #d8d8d8;
}

#style-2::-webkit-scrollbar {
    width: 5px;
    background-color: #D8D8D8FF;
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(32, 86, 86);
    background-color: #205656;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
    .basket_adr_block {
        width: 73vw;
        display: block;
        overflow: auto;
    }
    .basket_block {
        width: 460px;
    }
    .prod_name {
        width: 77%;
    }
    .order__block {
        height: 36vh;
    }
}



@media (max-width: 768px) {
    .col_btm_prod {
        font-size: 60px;
        width: 95px;
        height: 95px;
    }
    .col_num_prod {
        font-size: 60px;
        padding: 0 10px ;
    }
    .basket_adr_block {
        display: block;
        overflow: auto;
    }
    .basket_block, .adr_block {
        width: 1160px;
    }
    .order__block {
        height: 80vh;
    }
    .adr_block {
        margin-top: 20px;
    }
    .text_img_block img {
        width: 76px;
    }
    .text_img_block h3 {
        font-size: 56px;
    }
    .prod_name {
        font-size: 36px;
        margin-right: 35px;
    }
    .col_txt, .price_txt {
        font-size: 30px;
    }
    .col_btm, .col_num, .price, .sum_price {
        font-size: 36px;
    }
    .del_prod {
        width: 35px;
        height: 35px;
    }
    .del_prod:before, .del_prod:after {
        height: 4px;
        width: 26px;
    }
    .del_prod:after {
        top: 46%;
    }
    .str__block div {
        font-size: 32px;
    }
    .str__input, .komm__input {
        height: 70px;
        font-size: 36px;
    }
    .komm__input {
        height: 150px;
    }
    .home__input {
        width: 65px;
        height: 65px;
        font-size: 36px;
    }
    .str__home__block {
        align-items: center;
    }
    input[type="radio"] {
        width: 55px;
        height: 55px;
    }
    label {
        font-size: 36px;
        margin-left: 15px;
    }
    .col_btm_prod {
        width: 70px;
        height: 50px;
    }
    .btn__order__text {
        font-size: 55px;
    }
}

@media (max-height: 600px) {
    .order__block {
        height: 66vh;
    }

    .total__txt {
        font-size: 20px;
    }
    .total {
        font-size: 20px;
        width: 90px;
    }
    .usl_block {
        font-size: 14px;
    }
    .btn_on_time {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .col_btm_prod {
        font-size: 60px;
        width: 40px;
        height: 40px;
        padding-top: 0;
    }
    .col_num_prod {
        font-size: 60px;
        padding: 0 10px ;
    }

    .basket_block, .adr_block {
        width: 93vw;
    }
    .order__block {
        height: 45vh;
        max-width: 92vw;
    }
    .adr_block {
        margin-top: 20px;
        width: 91vw;
    }
    .text_img_block img {
        width: 40px;
    }
    .text_img_block h3 {
        font-size: 30px;
    }
    .prod_name {
        font-size: 16px;
        margin-right: 5px;
        width: 60%;
    }
    .order {
        width: 100%;
    }
    .col_txt, .price_txt {
        font-size: 16px;
    }
    .col_btm, .col_num, .price, .sum_price {
        font-size: 16px;
    }
    .del_prod {
        width: 25px;
        height: 25px;
        padding: 3px;
    }
    .del_prod:before, .del_prod:after {
        height: 3px;
        width: 16px;
        top: 45%;
    }

    .str__block div {
        font-size: 17px;
    }
    .str__input, .komm__input {
        width: 87vw;
        height: 34px;
        font-size: 20px;
    }
    .komm__input {
        height: 120px;
    }
    .home__input {
        width: 22px;
        height: 22px;
        font-size: 16px;
    }
    .str__home__block {
        align-items: center;
    }
    input[type="radio"] {
        width: 25px;
        height: 25px;
    }
    label {
        font-size: 16px;
        margin-left: 15px;
    }
    .btn__order__text {
        font-size: 46px;
    }

}

@media (max-width: 375px) {
    .home__input {
        width: 20px;
        height: 20px;
        font-size: 14px;
    }
    .str__block div {
        font-size: 15px;
    }
}