.logins__block {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

.box {
    position: relative;
    width: 380px;
    height: 420px;
    background: #184141;
    border-radius: 10px;
    overflow: hidden;
}

.box:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg,transparent,transparent,#66A7AB,#66A7AB,#66A7AB);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}

.box:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg,transparent,transparent,#66A7AB,#66A7AB,#66A7AB);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}

.borderLine {
    position: absolute;
    top: 0;
    inset: 0;
}

.borderLine:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg,transparent,transparent,#D8B164,#D8B164,#D8B164);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -1.5s;
}

.borderLine:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg,transparent,transparent,#D8B164,#D8B164,#D8B164);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -4.5s;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.box form {
    position: absolute;
    inset: 4px;
    background: #205656;
    padding: 50px 40px;
    border-radius: 10px;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.box form h2 {
    color: #fff;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 50px;
}

.box form .inputBox {
    position: relative;
    width: 300px;
    margin-top: 35px;
}

.box form .inputBox_reg {
    margin-top: 25px;
}

.box form .inputBox input {
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    color: #205656;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
}

.box form .inputBox span {
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    color: #D8B164;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.box form .inputBox input:valid ~ span,
.box form .inputBox input:focus ~ span {
    color: #d7a33b;
    font-size: 0.75em;
    transform: translateY(-34px);
}

.box form .inputBox i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #D8B164;
    border-radius: 5px;
    overflow: hidden;
    transition: 0.5s;
    pointer-events: none;
}

.box form .inputBox input:valid ~ i,
.box form .inputBox input:focus ~ i {
    height: 44px;
}

.box form .links {
    display: flex;
    justify-content: space-between;
}

.box form .links a {
    margin: 10px 0;
    font-size: 0.75em;
    color: #66A7AB;
}

.box form .links a:hover {
    color: #3da5ab;
}

.box form input[type="submit"] {
    border: none;
    outline: none;
    padding: 9px 25px;
    background: #66A7AB;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 0.9em;
    width: 150px;
    margin-top: 10px;
    font-family: "TangakRegular";
    transition: 0.5s;
}


.box form input[type="submit"]:active {
    opacity: 0.8;
}

.box form input[type="submit"]:hover {
    background: #3da5ab;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .logins__block {
        min-height: 80vh;
    }
    .box {
        width: 600px;
        height: 600px;
    }
    .box:before {
        width: 600px;
        height: 600px;
    }
    .box:after {
        width: 600px;
        height: 600px;
    }
    .borderLine:before {
        width: 600px;
        height: 600px;
    }
    .borderLine:after {
        width: 600px;
        height: 600px;
    }
    .box form h2 {
        font-size: 45px;
    }
    .box form .links a {
        font-size: 2.15em;
    }
    .box form input[type="submit"] {
        font-size: 1.5em;
        width: 210px;
    }
    .box form .inputBox {
        width: 510px;
    }
    .box form .inputBox span {
        font-size: 2em;
    }
    .box form .inputBox input {
        font-size: 2em;
        height: 110%;
    }
    .box form .inputBox input:valid ~ span,
    .box form .inputBox input:focus ~ span {
        font-size: 2em;
    }
}

@media (max-width: 540px) {
    .logins__block {
        min-height: 73vh;
    }
    .box {
        width: 450px;
        height: 450px;
    }
    .box:before {
        width: 450px;
        height: 450px;
    }
    .box:after {
        width: 450px;
        height: 450px;
    }
    .borderLine:before {
        width: 450px;
        height: 450px;
    }
    .borderLine:after {
        width: 450px;
        height: 450px;
    }
    .box form h2 {
        font-size: 32px;
    }
    .box form .links a {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .logins__block {
        min-height: 50vh;
    }
    .box {
        width: 360px;
        height: 420px;
    }
    .box:before {
        width: 360px;
        height: 420px;
    }
    .box:after {
        width: 360px;
        height: 420px;
    }
    .borderLine:before {
        width: 360px;
        height: 420px;
    }
    .borderLine:after {
        width: 360px;
        height: 420px;
    }
    .box form h2 {
        font-size: 42px;
        margin-bottom: 30px;
    }
    .box form .links a {
        font-size: 1.75em;
    }
    .box form input[type="submit"] {
        font-size: 1.5em;
        width: 180px;
    }
    .box form .inputBox {
        width: 280px;
    }

    .box form .inputBox input:valid ~ span,
    .box form .inputBox input:focus ~ span {
        font-size: 2em;
    }
}

@media (max-width: 390px) {
    .logins__block {
        min-height: 60vh;
    }
}

@media (max-width: 375px) {
    .logins__block {
        min-height: 70vh;
    }
}