.nav__block {
    background: #205656;
    width: 170px;
    height: 74vh;
    border-radius: 5px;
    overflow: auto;
}

.basket__btn {
    position: relative;
    display: flex;
    align-items: center;
    background: #D8B164;
    border-radius: 5px;
    padding: 5px;
    transition: 0.5s;
    border: 1px solid #D8B164;
    cursor: pointer;
    color: #fff;
}

.basket__btn__prod {
    width: 150px;
    margin: 10px;
}

.basket__btn:hover {
    background: #d7a33b;
}


.basket__btn img {
    width: 1vw;
    margin: 0 10px 0 5px;
}

.basket__btn a {
    /*text-decoration: none;*/
    color: #fff;
    margin-right: 40px;
}

.basket__num {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #66A7AB;
    text-align: center;
    font-size: 14px;
}

.nav__btn {
    display: flex;
    align-items: center;
    background: #66A7AB;
    margin: 10px 10px 5px 10px;
    border-radius: 5px;
    padding: 5px;
    transition: 0.5s;
    border: 1px solid #66A7AB;
}

.nav__btn.active {
    box-shadow: inset 0 0 8px rgba(0,0,0,0.6);
    background: #205656;
    border: 1px solid #D8B164;
    cursor: auto;
}

.nav__btn:hover {
    background: #3da5ab;
}

.nav__btn.active:hover {
    background: #205656;
}

.nav__img {
    width: 22px;
    height: 22px;
    background: #D8B164;
    border-radius: 50%;
    margin-right: 5px;

}

.nav__img img {
    margin: 2px 0 0 2px;
    width: calc(100% - 4px);
}

.nav__link:nth-child(3) .nav__img img {
    width: calc(100% - 2px);
}


.nav__link {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.nav__link:nth-child(3),
.nav__link:nth-child(4) {
    font-size: 12px;
}

.nav__link.active {
    color: #D8B164;
}

.nav__link__basket.active {
    color: #fff;
}

.nav__link__basket.active .basket__btn {
    box-shadow: inset 0 0 8px rgba(0,0,0,0.6);
    border: 1px solid #D8B164;
}

.nav__link.active .nav__btn {
    background: #205656;
    border: 1px solid #D8B164;
    box-shadow: inset 0 0 8px rgba(0,0,0,0.6);
}

.subcategory_block {
    display: none;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 5px;
    text-decoration: none;
    transition: 0.5s;
    color: #fff;
}
.subcategory_block img {
    width: 24px;
    margin-right: 5px;
}

.subcategory_block:hover {
    color: #d7a33b;
}

.subcategory_block.active {
    color: #d7a33b;
}

.nav__link.active .subcategory_block {
    display: flex;
}

@media (max-width: 1200px) {
    .nav__block {
        overflow: auto;
        width: 300px;
        height: 82vh;
    }
    .nav__link {
        font-size: 22px;
    }
    .nav__link:nth-child(3), .nav__link:nth-child(4) {
        font-size: 20px;
    }
}

@media (max-width: 992px) {
    .nav__block {
        width: 400px;
    }
    .basket__btn img {
        width: 3vw;
    }
    .basket__btn__prod {
        width: 160px;
    }
}

@media (max-width: 768px) {
    .nav__block {
        display: none;
    }
    .basket__btn__prod {
        font-size: 24px;
    }
    .basket__btn img {
        width: 4vw;
    }
    .nav__link {
        font-size: 24px;
    }
    .nav__link:nth-child(3), .nav__link:nth-child(4) {
        font-size: 22px;
    }
    .nav__img {
        width: 35px;
        height: 35px;
    }
    .basket__btn__prod {
        width: 330px;
    }

    .basket__num {
        width: 50px;
        height: 50px;
        font-size: 38px;
    }

    .subcategory_block img {
        width: 60px;
    }
    .nav__img {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 540px) {
    .subcategory_block img {
        width: 30px;
    }
    .basket__btn__prod {
        width: 290px;
    }
    .nav__link {
        font-size: 16px;
    }
    .nav__link:nth-child(3), .nav__link:nth-child(4) {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .basket__btn__prod {
        width: 300px;
    }
    .basket__btn img {
        width: 12vw;
    }
    .basket__num {
        width: 50px;
        height: 50px;
        font-size: 38px;
    }
    .nav__link {
        font-size: 27px;
    }
    .nav__link:nth-child(3), .nav__link:nth-child(4) {
        font-size: 26px;
    }
    .subcategory_block img {
        width: 30px;
    }
    .nav__img {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 320px) {

}