.menu_block {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    transform: translateX(-100%);
    transition: all 0.4s;
}

.menu_block.active {
    transform: translateX(0);
}

.nav_adaptive_block {
    display: block;
    position: fixed;
    top: 230px;
    width: 350px;
    background: #184141;
    left: 0;
    border-radius: 10px;
    padding: 5px 0;
}

.nav_adaptive_block_img {
    width: 180px;
    margin: 0 auto;
}

.nav_adaptive_block_img img {
    width: 100%;
}

@media (max-width: 768px) {
    .nav_adaptive_block {
        top: 764px;
    }
    .nav_adaptive_block_img {
        width: 270px;
    }
    .menu_block {
        transform: translateX(-100%);
        overflow: auto;
        height: 175vh;
    }
}

@media (max-width: 540px) {
    .nav_adaptive_block {
        top: 348px;
        width: 310px;
    }
    .nav_adaptive_block_img {
        width: 100px;
    }
    .nav__img {
        width: 30px;
        height: 30px;
    }
    .menu_block {
        height: 142vh;
    }
}

@media (max-width: 480px) {
    .nav_adaptive_block {
        top: 300px;
        width: 100%;
    }
    .nav_adaptive_block_img {
        width: 150px;
    }
    .menu_block {
        transform: translateX(-100%);
    }
}

@media (max-width: 430px) {
    .nav_adaptive_block {
        top: 694px;
        width: 100%;
    }
    .menu_block {
        height: 175vh;
    }
}

@media (max-width: 414px) {
    .nav_adaptive_block {
        top: 642px;
        width: 100%;
    }
    .menu_block {
        height: 170vh;
    }
}

@media (max-width: 390px) {
    .nav_adaptive_block {
        top: 595px;
        width: 100%;
    }
    .menu_block {
        height: 170vh;
    }
}

@media (max-width: 375px) {
    .nav_adaptive_block {
        top: 414px;
        width: 100%;
    }
    .menu_block {
        height: 160vh;
    }
}

@media (max-width: 360px) {
    .nav_adaptive_block {
        top: 490px;
        width: 100%;
    }
    .menu_block {
        height: 165vh;
    }
}