.basket_zero_block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1002;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgb(32, 86, 86, 0.7);
}

.basket_zero {
    position: relative;
    background: #3da5ab;
    width: 400px;
    padding: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.basket_zero_err {
    width: 530px;
}

.error_text {
    font-size: 18px;
    color: #fff;
}

.error_link {
    display: flex;
}

.error_link_ul {
    display: block;
    margin-left: 20px;
}

.error_link h4 {
    margin-right: 10px;
    color: #184141;
}

.error_link a {
    color: #fff;
    transition: 0.5s;
}

.error_link a:hover {
    color: #d7a33b;
    text-decoration: none;
}

.basket_zero img {
    width: 120px;
}

.basket_zero_text {
    color: #ffffff;
    font-size: 36px;
    margin-left: 10px;
}

.basket_zero_close {
    width: 15px;
    height: 15px;
    position: absolute;
    padding: 2px;
    right: 5px;
    top: 5px;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
}

.basket_zero_close:after {
    content: "";
    display: block;
    background-color: #d70000;
    position: absolute;
    top: 48%;
    width: 9px;
    height: 1px;
    transform: rotate(45deg);
}

.basket_zero_close:before {
    content: "";
    display: block;
    position: absolute;
    top: 48%;
    background-color: #d70000;
    width: 9px;
    height: 1px;
    transform: rotate(315deg);
}

.visible_reklama {
    position: relative;
    width: 450px;
}

.visible_reklama img {
    width: 100%;
    border-radius: 10px;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .basket_zero_block {
        height: 100%;
    }
    .visible_reklama {
        width: 700px;
    }
    .basket_zero_close {
        width: 50px;
        height: 50px;
    }
    .basket_zero_close:before, .basket_zero_close:after {
        width: 44px;
        height: 3px;
    }
}

@media (max-width: 480px) {
    .visible_reklama {
        width: 90px;
    }

    .basket_zero {
        width: 373px;
        padding: 10px;
    }
    .basket_zero img {
        width: 90px;
    }
    .basket_zero_text {
        font-size: 50px;
    }
    .basket_zero_close {
        top: -55px;
    }
}

@media (max-width: 320px) {

}