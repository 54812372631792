.preload_block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1002;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(32, 86, 86, 0.7);
}

.preload {
    display: flex;
    align-items: center;
}

.preload img {
    width: 80px;
}

.preload_text {
    font-size: 26px;
    color: #ffffff;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {

}

@media (max-width: 480px) {

}

@media (max-width: 320px) {

}