.order_block_details {
    margin: 0 auto;

}

.name_order_block {
    display: flex;
    align-items: center;
    margin-left: 100px;
}

.name_order_block img {
    width: 34px;
}

.name_order_block div {
    margin-left: 5px;
    color: #205656;
    font-size: 24px;
}

.order_all_block {
    position: relative;
    padding: 10px;
    background: #D9D9D9;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
}

.reset_block {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #ffffff;
    transition: 0.5s;
    cursor: pointer;
}

.reset_block:hover {
    border: 1px solid #d7a33b;
}

.reset_block_img {
    width: 25px;
    margin: 2px;
}

.order_all_block_img {
    width: 150px;
}

.adr_and_img {
    display: flex;
    justify-content: space-around;
}

.adr_txt,
.status_txt {
    color: #205656;
}

.pay_block {
    display: flex;
}

.pay,
.summ {
    margin-right: 5px;
}

.summ {
    color: #d7a33b;
}

.status_block {
    display: flex;
    align-items: center;
}

.status {
    margin-left: 10px;
    font-size: 22px;
}

.adr_block_order {
    margin-top: 15px;
}

.user_in_order_block {
    display: flex;
}

.user_in_order_title {
    margin-right: 10px;
    color: #205656;
}

.user_in_order_name {
    font-size: 18px;
}

.product_order_block {
    height: 35vh;
    margin: 10px;
    padding: 0 0 10px 0;
    color: #205656;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #205656;
    overflow: auto;
}

.product_str_name_block {
    display: flex;
    justify-content: space-between;
    background: #205656;
    border-radius: 7px 7px 0 0;
}

.product_str_name,
.prod_str {
    font-size: 14px;
    color: #ffffff;
    margin-left: 20px;
    padding: 2px 0;
}

.prod_str:last-child {
    margin-right: 10px;
}

.prod_str_price_block {
    display: flex;
}

.product__name_block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #205656;
}

.product__price_block {
    display: flex;
}

.product__block {
    display: flex;
}

.product__block {
    font-size: 14px;
    margin-left: 20px;
    padding: 2px 0;
}

.product__block:last-child {
    margin-right: 5px;
}

.product__name {
    font-size: 14px;
    padding: 2px 0 2px 5px;
    width: 331px;
}

.btn__order__job {
    background: #66A7AB;
    margin-right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
}

.btn__order__job img {
    width: 26px;
}

.btn__order__job div {
    color: #205656;
}

.btn__order__good img {
    width: 26px;
}

.btn__order__job:hover {
    background: #3da5ab;
}

#style-3::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgb(253, 253, 254);
    border-radius: 10px;
    background-color: #FDFDFEFF;
}

#style-3::-webkit-scrollbar
{
    width: 5px;
    background-color: #205656;
}

#style-3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(32, 86, 86);
    background-color: #205656;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-height: 600px) {
    .order_block_details {
        overflow: auto;
    }
}

@media (max-width: 768px) {
    .name_order_block img {
        width: 65px;
    }
    .name_order_block div {
        font-size: 35px;
    }
    .reset_block {
        width: 50px;
        height: 50px;
    }
    .reset_block_img {
        width: 45px;
    }
    .user_in_order_title {
        font-size: 30px;
    }
    .user_in_order_name {
        font-size: 30px;
    }
    .adr_txt, .status_txt, .order_address, .pay_block {
        font-size: 30px;
    }
    .order_all_block_img {
        width: 200px;
    }
    .product_str_name, .prod_str, .product__name, .product__block {
        font-size: 24px;
    }
    .product_order_block {
        height: 37vh;
    }
    .total__txt {
        font-size: 36px;
    }
    .total {
        width: 170px;
        font-size: 38px;
    }
    .btn__order__good img {
        width: 36px;
    }
    .btn__order__txt {
        font-size: 36px;
    }
    .order_block {
        width: 95vw;
    }
    .order_block_details {
        width: 97vw;
    }
    .total__txt {
        font-size: 50px;
    }
    .total {
        width: 250px;
        font-size: 52px;
    }
    .usl_block, .btn_on_time {
        font-size: 36px;
    }
    .btn__order {
        margin-bottom: 20px;
    }
    .btn__order img {
        width: 70px;
    }
    .btn__order__txt {
        font-size: 56px;
    }

}

@media (max-width: 480px) {
    .product_order_block {
        height: 33vh;
    }
    .total__txt {
        font-size: 26px;
    }
    .total {
        width: 150px;
        font-size: 34px;
    }
    .usl_block, .btn_on_time {
        font-size: 16px;
    }
    .btn__order {
        margin-bottom: 20px;
    }
    .btn__order img {
        width: 30px;
    }
    .btn__order__txt {
        font-size: 26px;
    }
    .order_address {
        font-size: 15px;
    }
    .name_order_block {
        margin-left: 10px;
    }
    .name_order_block img {
        width: 40px;
    }
    .name_order_block div {
        font-size: 23px;
    }
    .user_in_order_title, .user_in_order_name, .pay_block {
        font-size: 25px;
    }
    .order_all_block_img {
        display: none;
    }
    .adr_block_order {
        margin-top: 0;
    }
    .product_str_name, .prod_str, .product__name, .product__block {
        font-size: 13px;
    }
}

@media (max-width: 375px) {
    .name_order_block div {
        font-size: 20px;
    }
    .user_in_order_title, .user_in_order_name, .pay_block, .adr_txt, .status_txt {
        font-size: 16px;
    }
}