.header__nav {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to bottom, #205656, #66A7AB);
    padding-top: 5px;
    align-items: center;
}

.adm_btn,
.adm_btn_login {
    background: #9A9B9F;
    color: #205656;
    border: none;
    border-radius: 5px;
    padding: 2px 5px;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
    font-family: "TangakRegular";
}

.adm_btn_login {
    background: #D8B164;
    padding: 2px 7px;
    font-size: 16px;
}

.adm_btn_login:hover {
    background: #d7a33b;
}

.adm_btn:hover {
    background: #8b8b8f;
}

.login__block {
    display: flex;
    border-radius: 5px;
    align-items: center;
    padding: 2px 5px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.header__login {
    color: #fff;
}

.header__exit img {
    cursor: pointer;
}

.login__block img {
    width: 24px;
    margin-left: 10px;
    margin-top: 2px;
}

.logo__block {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 0 0 5px 5px;
}

.logo__bg {
    background: #66A7AB;
    border-radius: 0 0 5px 0;
}

.logo__bg:first-child {
    border-radius: 0 0 0 5px;
}

.logo__bg img {
    width: 100%;
}

.logo__img {
    position: absolute;
    width: 390px;
    top: 6%;
    left: 410px;
}

.slogon {
    position: absolute;
    color: #205656;
    font-size: 26px;
    top: 77%;
    left: 39%;
    text-shadow: white 2px 1px;
}

@media (max-width: 1200px) {
    .adm_btn, .adm_btn_login, .header__login {
        font-size: 24px;
    }
    .login__block img {
        width: 50px;
    }
    .logo__img {
        width: 350px;
        left: 35%;
    }
    .slogon {
        left: 41%;
        font-size: 21px;
    }
}

@media (max-width: 992px) {
    .adm_btn, .adm_btn_login, .header__login {
        font-size: 24px;
    }
    .login__block img {
        width: 50px;
    }
    .logo__img {
        width: 300px;
        left: 33%;
    }
    .slogon {
        left: 38%;
        font-size: 21px;
    }
}

@media (max-width: 768px) {
    .adm_btn, .adm_btn_login, .header__login {
        font-size: 24px;
    }
    .login__block img {
        width: 50px;
    }
    .logo__img {
        width: 300px;
        left: 250px;
    }
    .slogon {
        left: 38%;
        font-size: 21px;
    }
}

@media (max-width: 540px) {
    .logo__block {
        grid-template-columns: 1fr;
    }
    .adm_btn_login {
        font-size: 40px;
        padding: 2px 22px;
    }
    .logo__bg__right {
        display: none;
    }
    .logo__img {
        width: 320px;
        left: 21%;
    }
    .slogon {
        font-size: 22px;
        top: 75%;
        left: 30%;
    }
    .adm_btn, .adm_btn_login, .header__login {
        font-size: 12px;
    }
    .login__block img {
        width: 25px;
    }
}

@media (max-width: 480px) {
    .logo__block {
        grid-template-columns: 1fr;
    }
    .adm_btn_login {
        font-size: 40px;
        padding: 2px 22px;
    }
    .logo__bg__right {
        display: none;
    }
    .logo__img {
        width: 260px;
        left: 70px;
    }
    .slogon {
        font-size: 17px;
        top: 75%;
        left: 30%;
    }
    .adm_btn, .adm_btn_login, .header__login {
        font-size: 12px;
    }
    .login__block img {
        width: 25px;
    }
}

@media (max-width: 430px) {
    .logo__img {
        left: 93px;
    }
}

@media (max-width: 414px) {
    .logo__img {
        left: 84px;
    }
}

@media (max-width: 390px) {
    .logo__img {
        left: 75px;
    }
}

@media (max-width: 360px) {
    .logo__img {
        width: 240px;
        left: 72px;
    }
}

@media (max-width: 320px) {

}