.admin_panel_btn_add_del {
    background: #a40a0a;
}

.admin_panel_line_one {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
    .update_product_spisok_block {
        width: 62vw;
    }
    .update_product_name {
        width: 420px;
        font-size: 14px;
    }

}

@media (max-width: 768px) {

}

@media (max-width: 480px) {

}

@media (max-width: 320px) {

}